<template>
  <div class="status">
    <div>
      <span>Status:</span>
      <span>{{ jobData.status | jobStatusFormat }}</span>
    </div>
    <!--    <div-->
    <!--      v-if="showAcceptButton()"-->
    <!--      class="accept"-->
    <!--      @click="accept">-->
    <!--      Accept-->
    <!--    </div>-->
  </div>
</template>

<script>
import baseMixin from "@/mixins/base-mixin";

export default {
  name: "Status",
  mixins: [baseMixin],
  props: {
    jobData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {};
  },
  methods: {
    /**
     * 接受订单状态
     */
    accept() {
      this.$confirm("", "Confirm accept?", {
        confirmButtonText: this.$t("confirm"),
        cancelButtonText: this.$t("cancel"),
        type: "warning",
        center: true,
        roundButton: true,
      }).then(() => {
        const url = `ebdn/jobs/${this.jobData.id}/accept`;
        this.$oneLoading.show("Saving...");
        this.$request
          .put({
            url,
          })
          .then((res) => {
            if (res?.code === 1000) {
              this.$emit("update:jobData", { ...this.jobData, status: 2 });
              this.$message.success("Job accepted successfully");
            }
          })
          .finally(() => {
            this.$oneLoading.hide();
          });
      });
    },

    /**
     * 显示接受按钮
     */
    showAcceptButton() {
      return this.jobData.status === 1;
    },
  },
  filters: {
    jobStatusFormat(value) {
      switch (value) {
        case 1:
          return "Pending";
        case 2:
          return "Accepted";
        case 3:
          return "In Progress";
        case 4:
          return "Completed Transaction";
        case 5:
          return "Cancelled";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.status {
  display: flex;
  align-items: flex-end;
  margin-bottom: 20px;

  div:first-child {
    margin-right: 20px;
    color: #6c6c6c;

    span {
      display: block;
    }

    span:last-child {
      font-size: 20px;
      text-transform: capitalize;
    }
  }

  .accept {
    @include flex-center-row;
    height: 32px;
    border-radius: 20px;
    padding: 0 20px;
    color: white;
    background-color: $primary-color;
    cursor: pointer;
  }
}
</style>
